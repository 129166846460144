import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function About() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="about-area page-section scroll-to-page" id="about">
        <div className="custom-container">
            <div className="about-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="lar la-user"></i> About
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>Every great developer<br/>
                         has an even <span>better story</span></h1>
                </div>
                <p className="scroll-animation" data-aos='fade-up'>A designer, front-end developer and full stack engineer. This is me, in a nutshell. I've spent the past 6 years channeling my passions for design, software building and product management into the skills necessary to lead product development in mobile and web.</p>
                <p className="scroll-animation" data-aos='fade-up'>I'm always striving to bring a 100% value to the work that I do, to be an invaluable asset to any team I work with and I have been doing this for 6+ years.</p>
                <p className="scroll-animation" data-aos='fade-up'>Although a bit quiet, I am a people person and always absolutely thrilled to meet new people and gain new experiences. I love the fact that design and development let me use my skills as an artist.</p>
            </div>
        </div>
    </section>
  )
}
