import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Service() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="services-area page-section scroll-to-page" id="services">
        <div className="custom-container">
            <div className="services-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-stream"></i> Services
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>My <span>Specializations</span></h1>
                </div>

                <div className="services-items">
                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-bezier-curve"></i>
                        <h2>Website Application Design and Development</h2>
                        <p>I design and create digital web products from unique ideas. I use Sketch for design, React and Node JS for frontend and backend development.</p>
                        <span className="projects">6 Projects</span>
                    </div>
                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-mobile"></i>
                        <h2>Mobile Development</h2>
                        <p>I build mobile apps for both android and iOS. I normally develop using React Native and Swift.</p>
                        <span className="projects">3 Projects</span>
                    </div>
                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-cloud"></i>
                        <h2>DevOps</h2>
                        <p>I've deployed and still deploy applications on AWS, Digital Ocean, Heroku and other servers. And also write scripts for configurations and maintenance.</p>
                        <span className="projects">4 Projects</span>
                    </div>
                </div>    
            </div>
        </div>
    </section>
  )
}
