import React,{useEffect} from 'react'
import {Link} from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Lightbox from 'lightbox2';
import 'lightbox2/dist/css/lightbox.min.css';
// import 'lightbox2/dist/js/lightbox.js';

export default function PortfolioAveila() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
  return (
    <section className="portfolio-area page-section scroll-to-page" id="portfolio">
        <div className="custom-container">
            <div className="portfolio-content">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-grip-vertical"></i> Aveila
                    </h4>
                    <h1 className="scroll-animation">Aveila</h1>
                    <h6>E-Commerce and Business Intelligence Tools</h6>
                </div>

                <div className="row portfolio-items">
                    <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                        <div className="portfolio-item portfolio-full">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/aveila.png" data-lightbox="example-1">
                                    <img src="../assets/images/aveila.png" alt="Portfolio"/>
                                </a>
                            </div>
                            <p className="mb-5">Aveila is an e-commerce platform which fully supports businesses which need an online presence and a way to effectively manage their day to day operations.</p>

                            <h4>Role and Achievements</h4>

                            <p>I worked on the Aveila system as a backend and full stack engineer and built most of the features within the system.</p>

                            <p>I have been able to maintain the system to optimum performance, and optimized it to be able to handle a lot of traffic and requests.</p>

                            <p>Aveila Tech was nominated for e-commerce day africa 2023 and made it to the top 9 finalists:</p>

                            <a href="https://ecommerceday.africa/2023/">https://ecommerceday.africa/2023/</a>
                        </div>
                    </div>

                    <div className="col-md-12 scroll-animation mb-5" data-aos='fade-right'>
                        <div className="mb-5">
                            <hr />
                        </div>
                    </div>

                    <div className="col-md-4 scroll-animation" data-aos='fade-right'>
                        <div className="portfolio-full-desc">
                            <h1>Aveila Business Portal</h1>
                            <p>The aveila portal is the gateway to a world of digital retail opportunities. Seamlessly register a business and unlock a powerful suite of tools and resources. Manage products, track orders, and connect with customers effortlessly.</p>
                        </div>
                    </div>

                    <div className="col-md-8 scroll-animation" data-aos='fade-right'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/aveilagt.png" data-lightbox="example-1">
                                    <img src="../assets/images/aveilagt.png" alt="Portfolio"/>
                                </a>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="">React</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 scroll-animation mb-5" data-aos='fade-right'>
                        <div className="mb-5">
                            <hr />
                        </div>
                    </div>

                    <div className="col-md-4 scroll-animation" data-aos='fade-right'>
                        <div className="portfolio-full-desc">
                            <h1>Aveila Dashboard</h1>
                            <p>The dashboard is the command center for e-commerce success. Gain access to a user-friendly, feature-rich interface that empowers the business to manage their store, products and customer interactions. From order tracking to analytics, it's a hub for informed decision-making and business growth.</p>
                        </div>
                    </div>

                    <div className="col-md-8 scroll-animation" data-aos='fade-right'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/aveila-dhbd.png" data-lightbox="example-1">
                                    <img src="../assets/images/aveila-dhbd.png" alt="Portfolio"/>
                                </a>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="">React</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 scroll-animation mb-5" data-aos='fade-right'>
                        <div>
                            <hr />
                        </div>
                    </div>

                    <div className="section-header">
                        <h6>Next Project</h6>
                    </div>

                    <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                        <div className="portfolio-item portfolio-full">
                            <Link to="/portfolio/dash">
                                <div className="portfolio-item-inner">
                                    {/* <a href="../assets/images/rbht.png" data-lightbox="example-1">
                                        <img src="../assets/images/rbht.png" alt="Portfolio"/>
                                    </a> */}

                                    <img src="../assets/images/dash.png" alt="Portfolio"/>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
