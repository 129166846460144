import React,{useEffect} from 'react'
import {Link} from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Lightbox from 'lightbox2';
import 'lightbox2/dist/css/lightbox.min.css';
// import 'lightbox2/dist/js/lightbox.js';

export default function PortfolioRedbird() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
  return (
    <section className="portfolio-area page-section scroll-to-page" id="portfolio">
        <div className="custom-container">
            <div className="portfolio-content">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-grip-vertical"></i> Redbird
                    </h4>
                    <h1 className="scroll-animation">Redbird Health Tech</h1>
                    <h6>Health Tech Application for Pharmacies and Hospitals</h6>
                </div>

                <div className="row portfolio-items">
                    <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                        <div className="portfolio-item portfolio-full">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/rbht.png" data-lightbox="example-1">
                                    <img src="../assets/images/rbht.png" alt="Portfolio"/>
                                </a>
                            </div>
                            <p className="mb-5">Redbird is a health tech company in Ghana that provides patients with easy access to their digital health records via 5-minute tests available at community pharmacies.</p>

                            <h4>Role</h4>

                            <p>I was part of the team which developed the application for collecting the test data. I mostly focused on frontend development and design with some slight development on the backend as well.</p>

                            <p>The Redbird team managed to raise funding to scale through their collaborative team effort and resilience.</p>

                            <a href="https://techcrunch.com/2021/03/23/ghanas-redbird-raises-1-5m-seed-to-expand-access-to-rapid-medical-testing-in-sub-saharan-africa/">Ghana’s Redbird raises $1.5M seed to expand access to rapid medical testing in sub-Saharan Africa</a>
                        </div>
                    </div>

                    <div className="col-md-12 scroll-animation mb-5" data-aos='fade-right'>
                        <div className="mb-5">
                            <hr />
                        </div>
                    </div>

                    <div className="col-md-4 scroll-animation" data-aos='fade-right'>
                        <div className="portfolio-full-desc">
                            <h1>Redbird Dashboard</h1>
                            <p>The dashboard serves as the main reporting point for the pharmcies to know how many patients and tests have been registered in the system.</p>

                            <p>Overall, its the main entry point which serves to inform the pharmacies about how well they are performing in terms of testing for illness. Its simplistic design is intentional to make ease of use and access really simple and easy.</p>
                        </div>
                    </div>

                    <div className="col-md-8 scroll-animation" data-aos='fade-right'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/rbht-dsbd.png" data-lightbox="example-1">
                                    <img src="../assets/images/rbht-dsbd.png" alt="Portfolio"/>
                                </a>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="">React</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 scroll-animation mb-5" data-aos='fade-right'>
                        <div className="mb-5">
                            <hr />
                        </div>
                    </div>

                    <div className="col-md-4 scroll-animation" data-aos='fade-right'>
                        <div className="portfolio-full-desc">
                            <h1>Redbird Test Kits</h1>
                            <p>The redbird test kits interface enabled pharmacies to be able to purchase test kits directly from Redbird. This makes it easier for the pharmacies to be able to keep tracking patients' test data without any interruptions.</p>
                        </div>
                    </div>

                    <div className="col-md-8 scroll-animation" data-aos='fade-right'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/rbht-ti.png" data-lightbox="example-1">
                                    <img src="../assets/images/rbht-ti.png" alt="Portfolio"/>
                                </a>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="">React</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 scroll-animation mb-5" data-aos='fade-right'>
                        <div>
                            <hr />
                        </div>
                    </div>

                    <div className="section-header">
                        <h6>Next Project</h6>
                    </div>

                    <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                        <div className="portfolio-item portfolio-full">
                            <div className="portfolio-item-inner">
                                <Link to="/portfolio/aveila">
                                    {/* <a href="../assets/images/rbht.png" data-lightbox="example-1">
                                        <img src="../assets/images/rbht.png" alt="Portfolio"/>
                                    </a> */}

                                    <img src="../assets/images/aveila.png" alt="Portfolio"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
