import React,{useEffect} from 'react'
import {Link} from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Lightbox from 'lightbox2';
import 'lightbox2/dist/css/lightbox.min.css';
// import 'lightbox2/dist/js/lightbox.js';

export default function PortfolioDash() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
  return (
    <section className="portfolio-area page-section scroll-to-page" id="portfolio">
        <div className="custom-container">
            <div className="portfolio-content">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-grip-vertical"></i> Dash
                    </h4>
                    <h1 className="scroll-animation">Dash</h1>
                    <h6>Payments and Remittance App and Website</h6>
                </div>

                <div className="row portfolio-items">
                    <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                        <div className="portfolio-item portfolio-full">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/dash.png" data-lightbox="example-1">
                                    <img src="../assets/images/dash.png" alt="Portfolio"/>
                                </a>
                            </div>
                            <p className="mb-5">Dash is a mobile payments and remittance platform built for an estimated 1.3 billion africans to conveniently make digital transactions. Users can send and receive money, make bill payments and send remittance across borders which could be instantly accessed by the receiving party.</p>

                            <h4>Role and Achievements</h4>

                            <p>I joined Dash as a Frontend Engineer and Founding Team member when it first started in 2019. I was able to apply my skills as both an Engineer and an Entrepreneur during the initial stages and its was on of the biggest challenges of my career.</p>

                            <p>I was able to foster a very collaborate work environment and built most of the frontend products, including a mobile app for the dash merchants.</p>

                            <p>I became the Head of Frontend Engineering department in 2021 and expanded my team across 3 countries, and effectively led the team to build entirely new products within the Dash ecosystem which drove an increase in customer retention, success and support.</p>

                            <p>Our most recognized achievement was our funding raise to make scaling easier.</p>

                            <a href="https://techcrunch.com/2022/03/07/ghanaian-fintech-dash-raises-32-8m-seed-to-build-connected-wallets-for-africans/?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAGC6UpcB0dHYXahQQqAxNLPtx2ixLaiVOpSPKXvrhx-qjHo5h9K_IcXXqUU4Lc_oPoPGDgdCwYRz3dhXzpCnS6Xqvfs3I9dqzxq5ZDpj2ZvbD6HLf-nktLENwMGWI2xPmPok5hOD0FZrNYhOBVzrQAccLoMhMm8vsRsWi1aKsA18">Ghanaian fintech Dash raises $32.8M seed to build connected wallets for Africans</a>
                        </div>
                    </div>

                    <div className="col-md-12 scroll-animation mb-5" data-aos='fade-right'>
                        <div className="mb-5">
                            <hr />
                        </div>
                    </div>

                    <div className="col-md-4 scroll-animation" data-aos='fade-right'>
                        <div className="portfolio-full-desc">
                            <h1>Dash Web</h1>
                            <p>The website for Dash serves as the digital gateway to the cutting-edge financial technology solutions. With a focus on mobile payments, the platform empowers individuals and businesses to make secure, convenient, and fast transactions.</p>

                            <p>In summary, the website for Dash serves as a comprehensive resource to inform, engage, and empower individuals and businesses and showcasing commitment to innovation, security and financial convenience.</p>
                        </div>
                    </div>

                    <div className="col-md-8 scroll-animation" data-aos='fade-right'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/dashweb-home.png" data-lightbox="example-1">
                                    <img src="../assets/images/dashweb-home.png" alt="Portfolio"/>
                                </a>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="">React</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 scroll-animation mb-5" data-aos='fade-right'>
                        <div className="mb-5">
                            <hr />
                        </div>
                    </div>

                    <div className="col-md-4 scroll-animation" data-aos='fade-right'>
                        <div className="portfolio-full-desc">
                            <h1>Dash App</h1>
                            <p>The mobile app revolutionizes financial transactions. Secure, user-friendly, and feature-rich, it offers seamless payments, money transfers, and account management. It has on-the-go convenience with advanced security, real-time alerts, and easy navigation.</p>
                        </div>
                    </div>

                    <div className="col-md-4 scroll-animation" data-aos='fade-right'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/homeone.png" data-lightbox="example-1">
                                    <img src="../assets/images/homeone.png" alt="Portfolio"/>
                                </a>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="">React</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 scroll-animation" data-aos='fade-right'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/sendtwo.png" data-lightbox="example-1">
                                    <img src="../assets/images/sendtwo.png" alt="Portfolio"/>
                                </a>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="">React</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 scroll-animation" data-aos='fade-right'></div>

                    <div className="col-md-4 scroll-animation" data-aos='fade-right'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/billone.png" data-lightbox="example-1">
                                    <img src="../assets/images/billone.png" alt="Portfolio"/>
                                </a>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="">React</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 scroll-animation" data-aos='fade-right'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/onbone.png" data-lightbox="example-1">
                                    <img src="../assets/images/onbone.png" alt="Portfolio"/>
                                </a>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="">React</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 scroll-animation mb-5" data-aos='fade-right'>
                        <div>
                            <hr />
                        </div>
                    </div>

                    <div className="section-header">
                        <h6>Next Project</h6>
                    </div>

                    <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                        <div className="portfolio-item portfolio-full">
                            <Link to="/portfolio/redbird">
                                <div className="portfolio-item-inner">
                                    {/* <a href="../assets/images/rbht.png" data-lightbox="example-1">
                                        <img src="../assets/images/rbht.png" alt="Portfolio"/>
                                    </a> */}

                                    <img src="../assets/images/rbht.png" alt="Portfolio"/>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
