import './assets/css/bootstrap.min.css'
import './css/App.css'
import './css/responsive.css'
import Global from './components/Global'
import PortfolioDash from './components/Main-Components/Portfolio-Dash'
import PortfolioRedbird from './components/Main-Components/Portfolio-Redbird'
import PortfolioAveila from './components/Main-Components/Portfolio-Aveila'
import { Routes, Route, Navigate } from 'react-router-dom';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Global currentPage="home" />} exact />
      <Route path="/about" element={<Global currentPage="about" />} exact />
      <Route path="/resume" element={<Global currentPage="resume" />} exact />
      <Route path="/contact" element={<Global currentPage="contact" />} exact />
      <Route path="/portfolio/dash" element={<PortfolioDash />} exact />
      <Route path="/portfolio/redbird" element={<PortfolioRedbird />} exact />
      <Route path="/portfolio/aveila" element={<PortfolioAveila />} exact />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;
