import React from 'react'
import Lsb from './Global-content/Lsb'
import Herosec from './Main-Components/Herosec'
import About from './Main-Components/About'
import Resume from './Main-Components/Resume'
import Service from './Main-Components/Service'
import Skills from './Main-Components/Skills'
import Portfolio from './Main-Components/Portfolio'
import PortfolioDash from './Main-Components/Portfolio-Dash'
// import Testimonials from './Main-Components/Testimonials'
import Clientlogos from './Main-Components/Clientlogos'
// import Pricing from './Main-Components/Pricing'
import Contact from './Main-Components/Contact'

export default function Main(props) {
  return (
    <main className="drake-main">
        <div id="smooth-wrapper">
            <div id="smooth-content">

                <Lsb/> {/* this component will be used by mobile devices */}

                {
                  props.currentPage === 'home' ? (
                    <>
                      <Herosec/>
                      <Portfolio/>
                      <Clientlogos/>
                      <Service/>
                      <Skills/>
                    </>
                  ) : (
                    null
                  )
                }

                {
                  props.currentPage === 'about' ? (
                    <>
                      <About/>
                    </>
                  ) : (
                    null
                  )
                }

                {
                  props.currentPage === 'resume' ? (
                    <>
                      <Resume/>
                    </>
                  ) : (
                    null
                  )
                }

                {
                  /* props.currentPage === 'portfolio/dash' ? (
                    <PortfolioDash/>
                  ) : (
                    null
                  ) */
                }

                {
                  props.currentPage === 'contact' ? (
                    <div style={{ marginTop: '5rem' }}>
                      <Contact/>
                    </div>
                  ) : (
                    null
                  )
                }
                
                {/* <Portfolio/> */}
                {/* <Testimonials/> */}
                
                {/* <Pricing/> */}
                {/* <Contact/> */}

            </div>
        </div>
    </main>
  )
}
