import React from 'react'
import { Link } from 'react-router-dom'

export default function Lsb() {
  return (
        <div className="left-sidebar">
            <div className="sidebar-header d-flex align-items-center justify-content-between">
                {/* <img src="./assets/images/logo.png" alt="Logo"/> */}
                <h4>Kwame Sarpong</h4>
                <span className="designation">Software Engineer, Designer and Mobile Dev</span>
            </div>
            <img className="me" src="./assets/images/me.jpg" alt="Me"/>
            {/* <h2 className="email">kayosarp@gmail.com</h2> */}
            {/* <h2 className="address">Based in Nairobi, Kenya</h2> */}
            <p className="copyright">&copy; {new Date().getFullYear()}. All Rights Reserved</p>
            <ul className="social-profile d-flex align-items-center flex-wrap justify-content-center">
                <li>
                    <a href="https://twitter.com/kosarpong" target="_blank" rel="noreferrer"><i className="lab la-twitter"></i></a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/in/kwame-owusu-sarpong-ba347373/" target="_blank" rel="noreferrer"><i className="lab la-linkedin"></i></a>
                </li>
                <li>
                    <a href="https://instagram.com/kaysarp_ui" target="_blank" rel="noreferrer"><i className="lab la-instagram"></i></a>
                </li>
                <li>
                    <a href="https://github.com/kwamesarpong" target="_blank" rel="noreferrer"><i className="lab la-github"></i></a>
                </li>
            </ul>
            <a href="mailto:kayosarp@gmail.com" className="theme-btn">
                <i className="las la-envelope"></i> Lets connect
            </a>
        </div>
  )
}   
