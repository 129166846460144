import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Resume() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="resume-area page-section scroll-to-page" id="resume">
        <div className="custom-container">
            <div className="resume-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-briefcase"></i> Resume
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'><span>Experience</span></h1>
                </div>

                <div className="resume-timeline">
                    <div className="item scroll-animation" data-aos='fade-right'>
                        <span className="date">2019 - Present</span>
                        <h2>Lead Frontend Engineer and Team Manager</h2>
                        <p>SPK Technologies Inc.</p>
                        <h2>Front-End Engineer</h2>
                        <p>SPK Technologies Inc.</p>
                    </div>
                    <div className="item scroll-animation" data-aos='fade-left'>
                        <span className="date">2017 - 2019</span>
                        <h2>Senior Engineer</h2>
                        <p>Flare Labs</p>
                    </div>
                    <div className="item scroll-animation" data-aos='fade-right'>
                        <span className="date">2016 - 2017</span>
                        <h2>Entrepreneur-in-training</h2>
                        <p>Meltwater Entrepreneurial School of Technology</p>
                    </div>
                    <div className="item scroll-animation" data-aos='fade-right'>
                        <span className="date">2015 - 2016</span>
                        <h2>Junior Level Engineer</h2>
                        <p>Mynt Enterprise</p>
                    </div>
                    <div className="item scroll-animation" data-aos='fade-right'>
                        <span className="date">2014 - 2015</span>
                        <h2>Administrative Assistant</h2>
                        <p>Quality Assurance and Planning Unit, University of Science and Technology</p>
                    </div>
                </div>

            </div>
        </div>
    </section>
  )
}
